@mixin icon-base($width, $height) {
  &::before {
    content: '';
    display: block;
    background-size: $width $height;
    height: $height;
    width: $width;
  }
}

.rr-empty-hanger {
  @include icon-base(88px, 88px);

  &::before {
    content: url('/assets/icons/empty-hanger.svg');
  }
}

.rr-delete {
  @include icon-base(24px, 24px);

  &::before {
    content: url('/assets/icons/delete.svg');
  }
}

.rr-delete-reject {
  @include icon-base(44px, 44px);

  &::before {
    content: url('/assets/icons/deleteReject.svg');
  }
}

.rr-plus {
  @include icon-base(14px, 14px);

  &::before {
    background-image: url('/assets/icons/plus.svg');
  }
}

.rr-styling-options {
  @include icon-base(18px, 18px);

  &::before {
    background-image: url('/assets/icons/styling-options.svg');
  }
}

.rr-tuck-in {
  @include icon-base(12px, 15px);

  &::before {
    background-image: url('/assets/icons/tuck-in.svg');
  }
}

.rr-tuck-out {
  @include icon-base(12px, 15px);

  &::before {
    background-image: url('/assets/icons/tuck-out.svg');
  }
}

.rr-view {
  @include icon-base(22px, 15px);

  &::before {
    background-image: url('/assets/icons/view.svg');
  }
}

.rr-un-view {
  @include icon-base(22px, 19px);

  &::before {
    background-image: url('/assets/icons/un-view.svg');
  }
}

.rr-hide {
  @include icon-base(22px, 15px);

  &::before {
    background-image: url('/assets/icons/view.svg');
  }
}

.rr-icon-avatar {
  @include icon-base(48px, 48px);

  &::before {
    background-image: url('/assets/img/avatar-placeholder.png');
  }
}

.rr-back-button {
  @include icon-base(16px, 16px);

  &::before {
    background-image: url('/assets/icons/filter-back-button.svg');
  }
}

.rr-selection-filter-menu {
  @include icon-base(18px, 12px);

  &::before {
    background-image: url('/assets/icons/selection-filter-menu.svg');
  }
}

.rr-selection-search-delete {
  @include icon-base(14px, 14px);

  &::before {
    background-image: url('/assets/icons/search-delete.svg');
  }
}

.icon-upperbody-new:before {
  content: url('/assets/icons/mannequin.svg');
}

.rr-arrow-right::before {
  content: url('/assets/icons/arrow-right.svg');
}

.rr-close {
  &::before {
    content: url('/assets/icons/close.svg');
  }

  height: 20px;
  width: 14px;
}

.rr-check {
  &::before {
    content: url('/assets/icons/check.svg');
  }

  height: 24px;
  width: 24px;
}

.rr-check-black {
  &::before {
    content: url('/assets/icons/check-black.svg');
  }

  height: 24px;
  width: 24px;
}

.rr-close-red {
  &::before {
    content: url('/assets/icons/close-red.svg');
  }

  height: 24px;
  width: 24px;
}

.rr-sync-error {
  &::before {
    content: url('/assets/icons/syncError.svg');
  }

  height: 48px;
  width: 48px;
}

.rr-sync-ok {
  &::before {
    content: url('/assets/icons/syncOk.svg');
  }

  height: 48px;
  width: 48px;
}

.rr-green-check-line {
  &::before {
    content: url('/assets/icons/green-check-line.svg');
  }

  height: 24px;
  width: 24px;
}

.rr-download {
  &::before {
    content: url('/assets/icons/download.svg');
  }

  height: 18px;
  width: 18px;
}

.rr-drop-files {
  &::before {
    content: url('/assets/icons/dropFiles.svg');
  }

  height: 18px;
  width: 16px;
}

.rr-inspect {
  &::before {
    content: url('/assets/icons/inspect.svg');
  }

  height: 18px;
  width: 18px;
}

.rr-expand-off {
  &::before {
    content: url('/assets/icons/expand-off.svg');
  }

  height: 16px;
  width: 16px;
}

.rr-expand {
  &::before {
    content: url('/assets/icons/expand.svg');
  }

  height: 16px;
  width: 16px;
}

.rr-pen {
  &::before {
    content: url('/assets/icons/pen.svg');
  }

  height: 24px;
  width: 24px;
}

.rr-page-navigation {
  &::before {
    content: url('/assets/icons/page-navigation-active-right.svg');
  }

  height: 16px;
  width: 16px;
}

.rr-dropdown {
  &::before {
    content: url('/assets/icons/dropdown.svg');
  }
}

.rr-no-result-overlay {
  &::before {
    content: url('/assets/icons/no-result-overlay.svg');
  }
}

.rr-close8x8 {
  &::before {
    content: url('/assets/icons/close-new.svg');
  }
}

.rr-sort {
  &::before {
    content: url('/assets/icons/sort.svg');
  }

  height: 16px;
  width: 16px;
}

.rr-sort-asc {
  &::before {
    content: url('/assets/icons/sort-asc.svg');
  }

  height: 16px;
  width: 16px;
}

.rr-sort-desc {
  &::before {
    content: url('/assets/icons/sort-desc.svg');
  }

  height: 16px;
  width: 16px;
}

.rr-check-line::before {
  content: url('/assets/icons/check-line.png');
}

.rr-more-horiz::before {
  content: url('/assets/icons/more-horiz.svg');
}

.rr-delete::before {
  content: url('/assets/icons/delete.svg');
}

.rr-logout::before {
  content: url('/assets/icons/logout.svg');
  height: 20px;
  width: 20px;
}

.rr-speed::before {
  content: url('/assets/icons/speed.svg');
  height: 20px;
  width: 20px;
}

.rr-collection::before {
  content: url('/assets/icons/collection.svg');
  height: 20px;
  width: 20px;
}

.rr-arrow-down {
  height: 24px;
  width: 24px;

  &::before {
    content: url('/assets/icons/arrow-down.svg');
  }
}

.rr-arrow-down-white {
  height: 24px;
  width: 24px;
  &::before {
    content: url('/assets/icons/arrow-down-white.svg');
  }
}

.rr-arrow-down-gray {
  height: 24px;
  width: 24px;
  &::before {
    content: url('/assets/icons/arrow-down-gray.svg');
  }
}

.rr-moveUp {
  height: 19px;
  width: 15px;
  &::before {
    content: url('/assets/icons/moveUp.svg');
  }
}

.rr-moveDown {
  height: 19px;
  width: 15px;
  &::before {
    content: url('/assets/icons/moveDown.svg');
  }
}

.rr-trash {
  height: 19.5px;
  width: 18px;
  &::before {
    content: url('/assets/icons/trash.svg');
  }
}

.rr-clipboard {
  height: 12px;
  width: 12px;
  &::before {
    content: url('/assets/icons/clipboard.svg');
  }
}

.rr-drag {
  height: 36px;
  width: 30px;
  &::before {
    content: '';
    background-size: cover;
    background: url('/assets/icons/drag-vertical.svg');
    width: 30px;
    height: 36px;
    display: block;
  }
}

.rr-edit {
  height: 24px;
  width: 24px;
  &::before {
    content: url('/assets/icons/edit.svg');
  }
}
