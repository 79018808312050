.page-footer {
  font-size: 10px;
  letter-spacing: 0.05rem;
  position: relative;
  @include dm-color(background, $purple, $white);
  width: 100%;
  padding: 0.25rem 1rem;
  overflow: hidden;
  bottom: 0;
  position: absolute;
  @include dm-color(color, $white, $gray-900);

  a {
    @include dm-color(color, $white, $black, 0.75);
    &:hover {
      @include dm-color(color, $white, $black, 1);
    }
  }
}
