.dressing-room-container {
  height: 650px;
}

.created-at {
  width: 150px;
}

.sku-header {
  width: 350px;
}

.state-header {
  width: 190px;
}

.type-radio {
  width: 20px;
  padding: 0;
}

.btn-radio {
  padding: 10px;
  left: -15px;
}

.type-checkbox {
  .table-headline-name {
    padding-right: 15px;
    width: calc(100% - 15px);
  }
}

.icon-header {
  padding-left: 2px !important;
}

.timer-overlay {
  background: lightgray;
  width: 71%;
  height: 81%;
  position: absolute;
  z-index: 1;
  opacity: 0.98;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  .timer-container {
    height: auto !important;
  }

  .base-timer {
    height: 130px !important;
    width: 130px !important;
  }

  .base-timer__label {
    height: 130px !important;
    width: 130px !important;
    font-size: 24px !important;
  }
}

.viewer-container {
  float: left;
  width: 72%;
  height: 85%;
  text-align: center;

  .create-download-overlay {
    z-index: 10;
    opacity: 0;
    position: absolute;
    width: 71%;
    height: 81%;
    background-color: #fff;
    transition: opacity 0.2s ease;

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: black 1px solid;

      img {
        padding-bottom: 5px;
      }
    }
  }

  .change-avatar {
    width: 90%;
    margin-top: 5px;
  }
}

.viewer-container:hover .create-download-overlay {
  opacity: 0.7;

  button {
    opacity: 1;
  }
}

.side-bar-container {
  float: left;
  width: 27%;
  height: 100%;

  .garment-list {
    height: 100%;
  }

  .btn-add-garment {
    width: 100%;
    margin-top: 5px;
  }
}

.btn-fist {
  margin-right: 5px;
}

.text-align-none {
  text-align: unset;
}

$gray-250: #c4c4c6;

.btn-w100-margin-top {
  width: 100%;
  margin-top: 5px;
}

.side-bar {
  width: 100%;
  height: 85%;

  .dressed-overlay {
    position: absolute;
    background: grey;
    width: 100%;
    height: 81%;
    top: 0;
    opacity: 0.5;
  }

  .box {
    width: 100%;
    height: 13rem;
    border: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    cursor: move;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding-top: 10px;
    transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .box:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    z-index: 10;
  }

  .boundary {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    max-width: 100%;
    border: dotted #ccc 2px;
  }

  .hover-button-trigger-wear {
    .hover-button {
      /*body.no-safari-browser*/
      display: inline;

      opacity: 1;
      bottom: 5px;
      left: 5px;

      .wear-item-icon {
        font-size: 20px;
        bottom: 15px;
        left: 15px;
        top: unset;

        position: absolute;
      }
    }

    &:after {
      /* body.no-safari-browser */
      display: none;
      background: rgba(255, 255, 255, 0.76);
      padding: 0.25rem 0.5rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
      font-size: 10px;
      box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
      width: 100%;
      text-align: center;
    }

    &.active,
    &:active,
    &:focus {
      @include dm-color(color, $black, $white, 0.5);

      &:after {
        display: block;
      }

      .hover-button {
        display: none;
      }
    }

    &.active:after {
      z-index: 10;
    }
  }

  .action-container {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 166px;
    border-top: black;
    border-top-width: 1px;
    border-top-style: solid;

    .action-button {
      border: none;
      background: transparent;
      padding: 8px;

      &:hover {
        background-color: $gray-250;
      }
    }
  }

  .garments {
    border-color: $gray-250;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    background-color: white;

    .item {
      max-height: 9rem;
    }
  }
}

.modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

  .modal-title {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0;
    width: 92%;
    justify-content: center;
    align-content: center;
  }
}

.no-result {
  .overlay {
    position: absolute;
    height: 75vh;
    width: 44vw;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    border-radius: 8px;
    .no-result-message {
      width: 17vw;
      color: #fff;
    }
    .archived-message {
      width: 17vw;
      color: #fff;
    }
  }
  img {
    height: 75vh;
    width: 44vw;
    background: #ede4db;
    border-radius: 8px;
  }
}
