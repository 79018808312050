.btn span.oi {
  font-size: 11pt;
  line-height: 100%;
  margin-right: 6px;
}

.btn-black {
  @include dm-color(color, $black, $gray-100);
  border-width: 1px;
  border-style: solid;
  @include dm-color(border-color, $gray-icon, $gray-100, 1, !important);
  border-radius: 5px;
  transition: background 250ms ease, color 250ms ease;
  @include dm-color(background, $white, $gray-800);

  span {
    margin: 0 !important;
  }

  &:before,
  *:before {
    @include dm-color(color, $black, $gray-100);
    transition: color 250ms ease;
  }

  &:hover {
    @include dm-color(background, $black, $white, 0.5);
    @include dm-color(color, $white, $gray-800, 1, !important);

    &:before,
    *:before {
      @include dm-color(color, $white, $gray-800, 1, !important);
    }
  }
}

.hover-button-trigger {
  position: relative;
  display: inline-block;
  z-index: 1;
  width: auto;
  transition: all 200ms ease;
  box-shadow: 0 -0.35rem 0.4rem rgba(0, 0, 0, 0);
  @include dm-color(color, $black, $gray-100);
  cursor: pointer;

  .tocopy {
    user-select: all;
    display: inline-block;
    position: relative;
    z-index: 9;
  }

  &:hover .hover-button {
    /*body.no-safari-browser*/
    display: block;
    opacity: 1;
  }
  &:after {
    /* body.no-safari-browser */
    display: none;
    content: 'copied';
    background: rgba(255, 255, 255, 0.76);
    padding: 0.25rem 0.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    font-size: 10px;
    box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
    width: 100%;
    text-align: center;
  }
  &.active,
  &:active,
  &:focus {
    @include dm-color(color, $black, $white, 0.5);

    &:after {
      display: block;
    }
    .hover-button {
      display: none;
    }
  }
  &.active:after {
    z-index: 10;
  }
}

.hover-button {
  display: none;
  position: absolute;
  border: none;
  @include dm-color(background, $white, $gray-800, 0.8);
  opacity: 0.45;
  transition: opacity 200ms ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  @include dm-color(color, $gray-blue, $gray-blue);
  z-index: 11;
  line-height: 1;

  span {
    opacity: 0.95;
    text-align: center;
    font-size: 10px;
    margin-right: 0.5rem;
  }
}

button[disabled],
button:disabled {
  cursor: default !important;
}

.btn-fixed {
  width: 100%;
  position: relative;

  &.ml-3 {
    width: calc(100% - 2rem);
  }
  span {
    overflow: hidden;
    display: block;
    white-space: nowrap;
    padding-right: 1rem;
  }
}
.btn-darkmode,
.btn-darkmode-background {
  position: relative;
  background: #8e8e8e;
  width: 2.5rem;
  height: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}
.btn-darkmode-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 1rem;
  transition: width 300ms ease;
  background: $green;

  .darkmode & {
    width: 2.5rem;
  }
}
.btn-round {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  left: -0.1rem;
  top: -0.15rem;
  background: silver;
  border-radius: 50%;
  z-index: 1;
  transition: left 300ms ease;

  .darkmode & {
    left: calc(100% - 1.1rem);
  }
}

.btn-overlay {
  width: 100%;
  position: absolute;
  text-align: right;
  z-index: 9;
  background: transparent !important;
  border-radius: 5px;
  /*
  .custom-group button + & {
    width: calc(100% - 30px);
    border: none;
    cursor: pointer;
  }
  */
}
.wabber-container {
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    background: gray;
    -webkit-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.39);
    box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.39);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
    z-index: 1;
    opacity: 0;
  }
  &.btn-animate-active {
    &:after {
      -webkit-animation: wabber 1s infinite;
      -moz-animation: wabber 1s infinite;
      -o-animation: wabber 1s infinite;
      animation: wabber 1s infinite;
    }
  }
}
.btn-animate {
  position: relative;
  display: block;
  z-index: 2;
}

@-webkit-keyframes wabber {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.75;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes wabber {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.75;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes wabber {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.75;
  }

  100% {
    opacity: 0;
  }
}

@keyframes wabber {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.75;
  }

  100% {
    opacity: 0;
  }
}

.btn {
  $btn-padding-y: 12px;
  $btn-padding-x: 24px;
  $btn-font-size: 14px;
  $btn-line-height: 24px !important;
  $btn-border-radius: 48px !important;
  // --bs-btn-font-weight: 500 !important;
  // --bs-btn-font-size: 14px !important;
  // --bs-btn-border-radius: 48px !important;
  // --bs-btn-padding-x: 24px !important;
  // --bs-btn-padding-y: 12px !important;
}
