@import 'variables';
@import 'mixins';

.general-content-column {
  width: auto !important;
}
.name-tooltip {
  text-transform: uppercase;
}
.asset-dashboard {
  .name {
    text-transform: uppercase;
    font-size: 1rem;
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .type {
    text-transform: capitalize;
  }
}
// .asset {
//   display: flex;
//   align-items: center;

//   .thumbnail-container {
//     margin-right: 8px;
//     height: 50px;
//     width: 50px;

//     .thumbnail {
//       width: 50px;
//       height: 100%;
//       border-radius: 4px;
//     }

//     .thumbnail-tooltip {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   .name {
//     text-transform: uppercase;
//     font-size: 1rem;
//     color: #000;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }

//   .type {
//     text-transform: capitalize;
//   }
// }

.seperate-line {
  border-top: 1px solid $gray-250;
}
