.dropdown {
  a {
    padding-left: 10px;
  }
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }
}
.canvas-container {
  height: 100%;
  .empty-avatar-overlay {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: table;
    text-align: center;
    border-color: $gray-250;
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;
    div {
      display: table-cell;
      vertical-align: middle;
      span {
        font-size: 30pt;
      }
      .sub-text {
        font-size: 20pt;
      }
    }
  }
  #canvasDressingRoom {
    width: 100%;
    height: 100%;
  }
}

.group-container {
  max-height: 100%;
  overflow-y: auto;
}

#canvas3d-dressing-room {
  height: 300px;
  width: 500px;
}
