.progress-bar-wrapper {
  position: static;
}

.progress-bar-container {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: wait;
}
.spinner-border {
  position: absolute;
  top: calc(50% - 3rem);
  z-index: 9;
  left: calc(50% - 1rem);
}

.progress-bar-component {
  position: absolute;
  top: 50%;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.progress-bar {
  background-color: $purple !important;
  border-radius: 10px;
}

.progress-bar-info-text {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: 16px;
  text-align: center;
  left: 0;
  z-index: 9;
}
