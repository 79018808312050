* {
  font-family: $font-family-base;
  font-style: normal;
}

body {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
  letter-spacing: -0.01em;
}
