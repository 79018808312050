// stylelint-disable declaration-no-important

// Contextual backgrounds

@mixin bg-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus() {
      background-color: darken($color, 10%) !important;
    }
  }
  @include deprecate('The `bg-variant` mixin', 'v4.4.0', 'v5', $ignore-warning);
}

@mixin bg-gradient-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background: $color
      linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  }
  @include deprecate(
    'The `bg-gradient-variant` mixin',
    'v4.5.0',
    'v5',
    $ignore-warning
  );
}
