/*.btn-calendar {
  height: 1.75rem;
  background-color: #ffffff;
  border-color: #ced4da;
  border-radius: 0;
  padding: 0;

  .oi {
    margin-right: 6px;
    margin-left: 6px;
  }
}

.btn-calendar:hover {
  color: #212529;
}*/

.time-input-field {
  margin-left: 6px;
}

.time-input-clear {
  margin-left: 2px;
  cursor: pointer;
}

.time-input-clear:hover {
  text-decoration: underline;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 0.375rem !important;
  background: none !important;
}
textarea {
  min-height: 6rem;
}

.checkbox-container {
  label {
    margin: 0;
    width: 100%;
    font-size: 11px;
    text-transform: uppercase;
  }
  input,
  select,
  textbox,
  .form-control {
    padding: 0rem 0.5rem;
    height: 1.75rem;
    font-size: 0.9em;
    min-width: 50px;
  }
  [data-invalid='true'] {
    border: 3px solid rgba(255, 0, 0, 0.4);
  }
  .direction-horizontal {
    .form-check {
      white-space: nowrap;
      display: inline-block;
      padding-right: 0.5rem;
    }
  }
}
.show-arrow-at-end {
  right: -0.1rem;
  border-radius: 0 5px 5px 0;
}
.block-design {
  .form-check {
    display: inline-block;
    margin-right: 0.5rem;

    label {
      display: inline-block;
      padding-left: 0.55rem !important;
      @extend .btn;

      &:before,
      &:after {
        content: none !important;
        display: none !important;
      }
    }
    input {
      display: none;

      &:not(:checked) + label {
        color: #27292e;
        border-color: #909090;
      }
      &:checked + label {
        font-weight: 600;
        @extend .btn-primary;
      }
    }
  }
}
.color-checkbox {
  text-align: center;
  .form-check {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 0.5rem;

    &:hover {
      background: none;
    }

    .form-check-label {
      width: 0;
      height: 0;
      text-indent: -9999em;
      position: static;

      &:before {
        width: 30px;
        height: 30px;
        border-width: 1px;
        border-color: #cecece !important;
        opacity: 0;
        transition: border 250ms ease;
        -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.24);
      }
      &:after {
        width: 24px !important;
        height: 24px !important;
        left: 3px !important;
        background: inherit !important;
        border-radius: 50%;
        opacity: 1 !important;
      }
    }

    .form-check-input:checked + label:before {
      opacity: 1;
    }
  }
}

.info-icon-no-clear-text-field {
  position: absolute;
  right: 12px;
  top: 12px;
}

.info-icon {
  position: absolute;
  right: 50px;
  top: 12px;
}

.info-icon-two-action-buttons {
  position: absolute;
  right: 90px;
  top: 12px;
}
.info-icon-no-clear-two-action-buttons {
  position: absolute;
  right: 50px;
  top: 12px;
}

.filled-out {
  background-color: $gray-lightblue !important;
  border-color: $purple !important;
  border-width: 2px !important;
}

.input-only {
  border-radius: 5px !important;
}
