@import 'variables';
@import 'mixins';

.in-progress {
  background: $yellow !important;
}
.left-align {
  text-align: left;
}
.preview {
  width: 100%;
}
.img-container {
  text-align: center;
}

$abs_max_height: 700px;
.outfit-overlay {
  position: relative;
  overflow: hidden;
  .img-abs {
    position: absolute;
    max-height: $abs_max_height;
    opacity: 0.4;
    bottom: 0;
    right: 0;
  }
  .img-fashion {
    max-height: 75vh;
    max-width: 50vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
