.drop-container {
  border-radius: 5px;
  height: 200px;
  text-align: center;
  color: $gray-600;
  border: 2px solid #bebebe;
  text-transform: uppercase;
  position: relative;
}
.required {
  border: 2px solid $color-pictofit-red;
  border-radius: 5px;
}
.drop-container-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.file-container {
  max-height: 400px;
  overflow-y: scroll;
}
.list-group {
  .spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 0.1em;
  }
}
.set-types-container {
  padding: 1rem;
  @include dm-color(background, $gray-100, $gray-800);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  font-size: 14px;

  label {
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
  }
  select {
    font-size: 14px;
    height: 2rem;
  }
}
.top-element {
  x-justify-content: space-around;
  x-align-items: stretch;
  .preview-container {
    flex: 0 0 170px;
    .image-preview {
      max-height: 150px;
      max-width: 150px;
      margin-right: 16px;
    }
  }
}
