.spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  left: 0;
  top: 0;
  z-index: 100;
}
.spinner-component {
  position: absolute;
}
.spinner-inner {
  text-align: center;
}
.spinner-border {
  color: $gray-800;
}
.loading-text {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background: white;
  box-shadow: 0px 0px 20px 5px rgb(255, 255, 255);
}
.no-loading-text {
  padding-bottom: 1.5rem;
}
@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
.loading-text span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 1.25em;
}
.loading-text span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-text span:nth-child(3) {
  animation-delay: 0.4s;
}
