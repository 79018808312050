@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Regular.otf') format('otf'),
    url('../assets/fonts/Inter/Inter-Regular.ttf') format('ttf'),
    url('../assets/fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Medium.otf') format('otf'),
    url('../assets/fonts/Inter/Inter-Medium.ttf') format('ttf'),
    url('../assets/fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Bold.otf') format('otf'),
    url('../assets/fonts/Inter/Inter-Bold.ttf') format('ttf'),
    url('../assets/fonts/Inter/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@import 'theme-fonts';
