@import 'variables';
.input-field {
  .form-group {
    display: flex;
    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}
.dropdown-component {
  margin-bottom: 1rem;
  display: flex;
  .dropdown-input-button::after {
    display: inline-block;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    font-size: 1rem;
    margin-left: -3px;
  }
  .has-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dropdown-input-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .has-clear-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .clear-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.invalid {
  border: 2px solid $color-pictofit-red;
}
