.navbar > .container {
  @include media-breakpoint-up('md') {
    display: block;
    width: 100%;
  }
}
.page-navbar {
  background: white;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 0.5rem 0;

  @include media-breakpoint-down('sm') {
    padding: 13px 0;
  }

  > div {
    @include media-breakpoint-down('md') {
      display: block !important;
    }
  }

  .nav-container {
    .show {
      @include media-breakpoint-down('md') {
        padding-top: 1rem;
        padding: 0;
        padding-bottom: 2rem;
      }
    }
    @include media-breakpoint-down('md') {
      padding-top: 1rem;
    }

    .navbar-toggler {
      position: absolute;
      right: 8px;
      top: 3px;
      border: none;
      height: auto;
      padding: 0.5rem;
    }

    .navbar-nav {
      > .nav-item {
        border: none;
        padding-left: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-down('md') {
          width: 100%;
          display: block;
          border-radius: 5px;
          margin-bottom: 0.5rem;

          button,
          .dropdown-menu {
            text-align: left;
            display: block;
            width: 100%;
          }
        }

        .dropdown-toggle {
          background: none;

          &:focus {
            box-shadow: none;
          }

          &[aria-expanded='true'] {
            border-radius: 5px 5px 0 0;
          }
        }

        .nav-link {
          @include dm-color(color, $black, $gray-100);
          text-transform: none;

          &:hover {
            @include dm-color(background, $gray-lightblue, $gray-darkblue-400);
          }

          &.dropdown-toggle {
            min-width: 10rem;
          }
        }

        &:not(.active) > .nav-link {
          @include dm-color(color, $black, $gray-100);
          transition: all 200ms ease;
          /*
          &:hover {
            @include media-breakpoint-up('sm') {
              color: $gray-icon;

              > a,
              button {
                color: $gray-icon !important;
              }
            }
          }*/
        }

        &.active {
          @include media-breakpoint-up('sm') {
            /*color: $gray-icon;*/

            /*
            > a,
            button {
              color: $gray-icon !important;
            }*/
          }
        }

        .nav-item {
          font-size: 0.9em;
          text-transform: none;
        }

        .dropdown-menu {
          border: 1px solid $gray-900;
          border-radius: 0 0 5px 5px;
          padding: 0;
          margin: 0;
          margin-top: -1px;

          a {
            @include dm-color(color, $gray-900, $gray-100);
          }
        }
      }
    }
  }

  .metanav {
    padding: 0;
    @include dm-color(color, $gray-900, $gray-100);
    margin: 0;

    @include media-breakpoint-down('sm') {
      position: absolute;
      bottom: 0;
      top: auto;
      right: 1rem;
      height: auto;
    }
    @include media-breakpoint-up('md') {
      display: block !important;
      margin-right: 0.5rem;
    }
    form {
      margin: 0 !important;
    }
    .btn {
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      border-radius: 0;
      text-align: center;
      width: 44px;
      height: 44px;
      padding: 0;
      display: inline-block;
      border-radius: 5px;

      span {
        top: 0;
        width: 100%;
        display: inline-block;
        margin: 0;
        height: 44px;

        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .clear-cache-container {
      text-align: center;
      @include dm-color(color, $white, $gray-800);
      position: relative;
      display: inline-block;

      &:hover .clear-cache-dropdown {
        display: block;
      }

      .clear-cache-title {
        text-align: center;

        .oi {
          margin: 0 !important;
        }
      }
      .tooltip {
        font-size: 12px;
      }
      .clear-cache-dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        border-width: 1px;
        border-style: solid;
        @include dm-color(border-color, $white, $gray-800);
        border-radius: 0 0 5px 5px;

        background: $gray-600;

        button {
          display: block;

          &:first-child {
            span {
              @include dm-color(color, $red, $red);
            }
            border-bottom: 1px solid;
            @include dm-color(border-color, $white, $gray-800);
          }
          &:last-child {
            span {
              @include dm-color(color, $yellow, $yellow);
            }
          }
        }
      }
    }
  }
}

.navbar-brand {
  position: relative;
  margin: 0;
  padding: 0;
  text-transform: none;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  @include media-breakpoint-down('sm') {
    padding-left: 1rem !important;
  }
  @include dm-color(color, $black, $gray-100, 0.8);
  transition: opacity 250ms ease;

  &:hover {
    @include dm-color(color, $black, $gray-100);
  }
}
ul.navbar-nav {
  width: 100%;
}
#navbarMain {
  max-width: 720px;
  margin: 0 auto;
}
