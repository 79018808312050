$rr-form-field-height: 64px;
$rr-border-radius: 8px;
$rr-border-color: rgba(0, 0, 0, 0.12);
$rr-form-field-label-color: rgba(0, 0, 0, 0.6);

body {
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #ed6755;
  border-radius: 2px;
}
