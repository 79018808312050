.ng-select {
  border: 1px solid #d9d9da;
  border-radius: 4px 0 0 4px;
  padding-bottom: 0;

  .ng-select-container {
    min-height: unset;

    .ng-value-container {
      border: 0;
      padding: 4px !important;

      .ng-value {
        margin: 0 2px !important;
        background-color: $purple !important;
        border-radius: 4px !important;
      }

      .ng-placeholder {
        visibility: visible;
        font-size: 0.75rem;
        line-height: 32px;
        padding-left: 8px;
      }
    }

    .ng-clear-wrapper {
      border-top: 0 solid transparent !important;
    }

    .ng-arrow-wrapper {
      border-top: 0 solid transparent !important;
    }
  }

  .ng-select-container.ng-has-value {
    .ng-value-container {
      .ng-placeholder {
        visibility: hidden;
      }
    }
  }

  .ng-select-container:after {
    border-bottom: 0;
  }
}

.ng-select-opened {
  .ng-select-container {
    .ng-value-container {
      .ng-placeholder {
        visibility: hidden;
      }
    }
  }
}
