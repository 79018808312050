@import '~@ng-select/ng-select/themes/material.theme.css';
@import 'mixin';
@import 'buttons';
@import 'checkbox-radio-buttons';
@import 'date-range';
@import 'dropdown';
@import 'multi-select';
@import 'filters-container';
@import 'navbar';
@import 'table-batch-options';
@import 'table-pagination';
@import 'table';
@import 'form-inputs';
@import 'progress';
@import 'breadcrumb';
@import 'footer';
@import 'spinner';
@import 'lightbox';
@import 'status-bar';
@import 'file-upload';
@import 'input-fields';
@import 'color-picker-field';
@import 'dressing-room';
@import 'stepper';
@import 'media-viewer';

.col-md-20 {
  @include media-breakpoint-up('md') {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.btn {
  opacity: 0.75;
  transition: opacity 250ms ease;
  letter-spacing: 0.07rem;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
  &:disabled {
    opacity: 0.3;
  }
}
.btn-clear-style,
.btn-clear-style:hover,
.btn-clear-style:focus,
.btn-clear-style:active {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: inherit;
  letter-spacing: 0;
}

.form-group {
  position: relative;
}
.form-control {
  &.ng-valid[required]:not(:disabled):not([readonly]),
  &.ng-valid.required:not(:disabled):not([readonly]) {
    border-left: 4px solid $purple;
  }

  &.ng-invalid:not(form):not(:disabled):not([readonly]) {
    border: 2px solid $color-pictofit-red;
  }
  &.ng-valid.required:not(form):not(:disabled):not([readonly]) {
    border: 2px solid $gray-400;
  }

  &.ng-untouched:not(:required):not(:disabled):not([readonly]) {
    border: 2px solid $gray-400;
  }

  label {
    font-size: $custom-input-font-size;
  }
  &:disabled,
  &[readonly] {
    border: none;
  }
}
.bs-datepicker-predefined-btns {
  .btn {
    text-align: center;
    font-size: 0.875rem;
    @include font-size(14px 14px 15px 16px 16px);
    background: none;
    color: black;
    border-width: 1px;
    border-style: solid;
    @include dm-color(border-color, $gray-200, $gray-700);
    transition: all 250ms ease;

    &:hover {
      @include dm-color(background, $gray-600, $gray-200);
      @include dm-color(color, $white, $gray-800);
    }
  }
}

.bs-datepicker {
  box-shadow: 0 2px 5px 0px #aaa;
}

.bs-datepicker-predefined-btns button.selected {
  color: white !important;
}

.input-overlay-disabled {
  border: none !important;
}

.form-group.form-check .form-group.form-check {
  margin-bottom: 0.25rem;
}

.alerts-container {
  position: fixed;
  width: 80%;
  max-width: 500px;
  display: block;
  z-index: 1999;
  top: 2rem;
  left: calc(50% - 250px);

  .alert-box {
    border-radius: 5px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);

    &.type-revision {
      max-width: 850px;
    }
  }
}

html,
body {
  width: 100%;
  height: 100%;
  // @include dm-color(background, $gray-400, $gray-700);
  @include dm-color(color, $gray-900, $gray-200);
  letter-spacing: 0.01rem;

  background: #f5f0eb;
}

body {
  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: 0.1rem;
}

h1 {
  letter-spacing: 0.05rem;
  padding: 0.75rem;
}

.page-navbar,
.page-footer,
.page-content {
  flex-shrink: 0;
}

.page-content {
  flex-grow: 1;
  z-index: 2;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  position: relative;
  pointer-events: all;

  @include media-breakpoint-up('sm') {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.selected {
  @include dm-color(
    color,
    $selected-hover-color,
    $selected-hover-color,
    1,
    !important
  );
}

.dropdown-breadcrumb {
  div {
    white-space: nowrap;
  }
}

/* bootstrap rewrite: */

.input-group-text {
  position: relative;
  min-width: $input-height;
  height: $input-height;
  right: auto;
  font-size: 0.5rem;

  .oi {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.dropdown-toggle {
  position: relative;
  text-align: left;
  padding-right: 2.5rem;

  &:after {
    top: 1px;
    display: inline-block;
    speak: none;
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    border: none !important;
    content: '\e034';
    width: 0.75rem;
    font-size: 0.7rem;
    text-align: center;
    box-sizing: content-box;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -2px;
  }
}
.modal-body {
  max-height: calc(100vh - 10rem);
  overflow: auto;
}
.text-danger {
  color: $color-pictofit-red;
}
.color-picker-field-container {
  .ng-valid[required]:not(:disabled):not([readonly]),
  .ng-valid.required:not(:disabled):not([readonly]) {
    border: 2px solid #d9d9da;
  }
}

/* Remove outline in safari */
canvas,
canvas:active,
canvas:focus-visible,
canvas:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

.clickable-item {
  cursor: pointer;
}

.form-check-label.boxes-right::after,
.form-check-label.boxes-right::before {
  left: auto !important;
  right: 0 !important;
  border-radius: 50% !important;
  background: $gray-400 !important;
}

.form-check-label.item-selected {
  font-weight: bold;
}
