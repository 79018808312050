@import 'bootstrap/scss/mixins';

/* Colors to change for Darkmode */
@mixin dm-color($attribute, $color, $color-dm, $opacity: 1, $important: '') {
  #{$attribute}: rgba(red($color), green($color), blue($color), $opacity) #{$important};
  transition: all 200ms ease;

  .darkmode & {
    #{$attribute}: rgba(
        red($color-dm),
        green($color-dm),
        blue($color-dm),
        $opacity
      )
      #{$important};
  }
}
@mixin my-box-shadow {
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.2);
}

@mixin status-field-before($parent, $color) {
  #{$parent}:before {
    background-color: desaturate($color, 5%) !important;
  }
  #{$parent}:after {
    background-color: desaturate($color, 5%) !important;
  }
}

@each $color, $value in $theme-colors {
  @include status-field-before('.bg-#{$color}', $value);
}
@each $color, $value in $state-colors {
  @include status-field-before('.bg-#{$color}', $value);
}

@mixin font-size(
  $customSize: (
    16px 16px 17px 17px 18px,
  )
) {
  /* Fontsize Responsive  */
  /* (default, tablet, desktop, desktop large, desktop xlarge)
  /* @include font-size(16px 14px 13px 13px 13px);  */
  font-size: nth($customSize, 1);

  @include media-breakpoint-up('md') {
    font-size: nth($customSize, 2);
  }
  @include media-breakpoint-up('lg') {
    font-size: nth($customSize, 3);
  }
  @include media-breakpoint-up('xl') {
    font-size: nth($customSize, 4);
  }
  @include media-breakpoint-up('xxl') {
    font-size: nth($customSize, 5);
  }
}
