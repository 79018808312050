.btn-overlay {
  width: 100%;
  position: absolute;
  text-align: right;
  z-index: 9;
  background: transparent !important;
  border-radius: 5px;
  /*
  .custom-group button + & {
    width: calc(100% - 30px);
    border: none;
    cursor: pointer;
  }

  */
}
