.breadcrumb-container {
  margin-top: 59px;
  font-size: 12px;
  color: white;

  li {
    a {
      color: $white;
      opacity: 0.75;
      transition: opacity 250ms ease;
      text-decoration: underline;

      &:hover {
        opacity: 1;
      }
    }
  }

  button {
    padding: 0 1rem;
    font-size: 1rem;
    height: 1rem;
    line-height: 0.5;
  }
}

.breadcrumb-item {
  position: relative;
}

.dropdown-breadcrumb {
  padding: 0.5rem 1rem;

  div {
    white-space: nowrap;
  }
}
