.pagination-container {
  &.top-pagination {
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: none;
    padding-top: 5px;

    pagination {
      z-index: 2;
      position: relative;
    }
  }

  &.bottom-pagination {
    margin-bottom: 1rem;
    min-height: 40px;
    @include dm-color(background, $gray-350, $gray-300);
    border-width: 1px;
    border-style: solid;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    @include dm-color(border-color, $gray-400, $gray-500);
    border-top: 0;
  }
  select {
    cursor: pointer;
    word-wrap: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0px;
    border: 1px solid;
    @include dm-color(border-color, $gray-900, $gray-400);
    border-radius: $border-radius;
    margin-right: 2rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-left: 0.5rem;
    padding-bottom: 0.2rem;
    outline: none;
  }
  .show-per-page-container,
  .show-per-page-container {
    font-size: 11px;

    label {
      margin-right: 0.5rem;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  pagination {
    display: inline-blocK;
    width: auto;
  }

  .pagination {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .page-item {
    border: 1px solid transparent;

    &.disabled {
      opacity: 0.25;
    }
    &.pagination-page {
      transition: all 200ms ease;
      color: $black;
      margin: 2px;

      &:hover {
        @include dm-color(background, $gray-200, $gray-800);
        @include dm-color(color, $white, $gray-800);
      }
    }

    &:hover {
      border-radius: 5px;
      color: $gray-900;
      border: 1px solid $gray-600;
    }
    &.active {
      border-style: solid;
      border-width: 1px;
      border-radius: $border-radius;
      @include dm-color(border-color, $gray-900, $gray-200);
    }
    .page-link {
      color: inherit;
      border: none;
      font-size: 12px;
      width: 25px;
      height: 25px;
      text-align: center;
      padding: 0.35rem 0;

      &:hover {
        background: none;
        @include dm-color(color, $gray-900, $gray-100);
      }
    }
  }

  .pagination-first,
  .pagination-last {
    white-space: nowrap;
  }
  .oi {
    @include dm-color(color, $gray-750, $gray-100);
    transition: color 200ms ease;

    &:hover {
      @include dm-color(color, $gray-900, $gray-100);
    }
  }

  .pagination-next,
  .pagination-last,
  .pagination-prev,
  .pagination-first {
    .page-link {
      font-size: 11px;
      padding-top: 0.5rem;
    }
  }
}
