.text-editor {
  padding: 0 2rem;

  .inner {
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    @include dm-color(border-color, $gray-400, $gray-500);
    padding: 0.5rem 1rem;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace;
    @include dm-color(color, $black, $gray-100);
  }
}
.json-editor {
  padding: 0.5rem 1rem;
}
.lightbox-controllers {
  .btn-arrow-left {
    text-align: left;
  }
}
.modal-content {
  height: auto;
  border-radius: 0;
}
.modal-header {
  border-bottom: 0;
}
.modal-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

h4 {
  display: block;
}
.p-lightbox {
  .json-editor {
    padding: 0.5rem 2rem;
  }
}
.p-lightbox {
  .json-editor {
    padding: 0.5rem 2rem;
  }
}
.lightbox-image-coutner {
  font-size: 1rem;
}
.lightbox-controllers {
  button {
    position: absolute;
    height: 5rem;
    width: 2rem;
    text-align: center;
    cursor: pointer;
    background: none;
    border: none;
    z-index: 9;
    top: calc(50% - 2.5rem);
    border: 1px solid;
    border-radius: 5px;
    @include dm-color(border-color, $gray-500, $gray-200);
    transition: background 200ms ease;

    &:hover {
      @include dm-color(background, $gray-100, $gray-800);
    }

    span.title {
      display: none;
    }
    span {
      position: absolute;
      top: 50%;
      left: calc(50% - 5px);
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .btn-arrow-left {
    left: 1rem;
  }
  .btn-arrow-right {
    right: 1rem;
  }
}

.modal-body-lightbox {
  position: relative;
  height: calc(100vh - 10rem);
  border-bottom: 5px double gray;
}
.image-container-grid {
  display: block;
  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
  margin: 2rem;
  left: 0;

  img,
  a,
  a span {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }

  a {
    display: none;
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.75);
    z-index: 5;
  }

  &:hover a {
    display: block;
  }
}

.image-container {
  display: block;
  position: absolute;
  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
  margin: 2rem;
  left: 0;

  img,
  a,
  a span {
    display: inline-block;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  a {
    display: none;
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.75);
    z-index: 5;
  }

  &:hover a {
    display: block;
  }
}

.modal-dialog.modal-xxl {
  max-width: 1400px;
}
