@import './variables';
@import './mixins';
@import './dashboard';
@import './request';
@import './dressing-room';
@import './icons';

body {
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: map-get($rr-colors, 'background');
  transition: all 0.2s ease;
  width: 100%;
  overflow-x: hidden !important;
}

.hidden {
  display: none !important;
}

.root-container {
  position: relative;

  @include dm-color(background, $white, $gray-800);
  padding: 1rem;
  border-radius: 5px;
  @include my-box-shadow;
  margin-bottom: 1rem;

  @include media-breakpoint-up('sm') {
    padding: 2rem;
  }
}

.status-field {
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 8pt;
  padding: 4px;
  background: none !important;
  display: inline-block;
  position: relative;
  padding-left: 1.5rem;
  color: $black;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    width: 1.15rem;
    height: 1.15rem;
    border-radius: 50%;
    top: 3px;
  }
}

.status-field-count {
  text-transform: uppercase;
  font-size: 8pt;
  padding: 5px;
  margin-left: auto;
}

.request-form-container {
  .buttons-container {
    float: right;
  }
}

.text-danger {
  color: $red;
}

.text-bold {
  font-weight: bold;
}

label {
  text-transform: uppercase;
}

.full-item {
  margin: 10px 0;
}

.field {
  display: flex;
  justify-content: space-between;
}

.circel {
  height: 30px;
  width: 30px;
  border: $gray-800;
  background: $state-deepskyblue;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  margin-top: 35px;
  text-align: center;
  color: white;
  font-size: 14pt;
  background: black;
}

.modal-items-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-item {
    margin: 5px;
  }
}

.w-250 {
  width: 250px;
}

.keyCode {
  opacity: 0.5;
}

.btn-custom-outline {
  border-color: rgba(0, 0, 0, 0.16) !important;

  &:hover {
    background: rgba(0, 0, 0, 0.06) !important;
  }
}

.btn-custom-outline-success {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $rr-approve-color !important;

  &:hover {
    color: $rr-approve-color !important;
  }
}

.btn-custom-outline-danger {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $rr-reject-color !important;

  &:hover {
    color: $rr-reject-color !important;
  }
}

.btn-close {
  position: absolute;
  right: 16px;
  top: 6px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: inherit;
  font-size: 2rem;
}

.download-modal {
  .modal-header {
    min-height: 3.5rem;
  }

  .modal-content {
    min-width: 25vw;
  }
}

.full-screen {
  .modal-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    background: black;
    border-radius: 0 !important;

    .vdr-container {
      width: unset;
      height: 100vh;
      display: flex;
      justify-content: center;

      .change-avatar-selection-container {
        left: 0;
      }

      canvas {
        height: 100% !important;
        width: 50% !important;
        top: unset !important;
        left: unset !important;
      }
    }
  }
}

.before-loading-indicator {
  z-index: 101;
}

.btn-primary {
  &:hover {
    background-color: #262626 !important;
  }
}
