.table-hover tbody tr:hover,
table,
input {
  @include dm-color(color, $black, $gray-100);
}

.width-overflow {
  width: 100% !important;
  overflow: auto !important;
}
.oi-timer {
  line-height: 12px;
  vertical-align: top;
}
.filter-btn-count {
  font-size: 11px;
}
.table-top-bar {
  background-color: $white;
  border-style: solid;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  @include dm-color(border-color, $gray-400, $gray-400);
  border-width: 1px;
  border-bottom: none;
  height: 40px;
  padding: 11px 0;
  position: relative;

  .justify-content-between {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.table-overflow-scroll {
  position: relative;
  width: auto;
  max-height: 100%;
  border-width: 1px;
  border-style: solid;
  @include dm-color(border-color, $gray-400, $gray-400);
  border-top: 0;

  &.table-pagination-disabled {
    margin-bottom: 1rem;
  }
  .table-overflow-scroll-inner {
    position: static;
    overflow: auto;

    &.table-show-history {
      max-height: calc(100vh - 15rem);
    }
  }

  table {
    margin-bottom: 0 !important;
  }
  tbody tr:hover {
    background: rgba(67, 140, 144, 0.1) !important;
  }
  th {
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.071rem;
    .icon-header {
      padding-left: 1rem;
    }
    &.edit-column {
      background: none;
      padding: 2px;
      @include dm-color(background, $gray-050, $white, 1, '!important');
      border-left: 1px solid $gray-400 !important;

      .oi-reload {
        width: 2rem;
        text-align: center;
        height: 2rem;
        position: relative;
        font-size: 0.75rem;

        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .table-overflow-scroll-inner {
    &.table-edit-2 {
      th.edit-column {
        width: calc(32.17px * 2 + 7px);
      }
    }
    &.table-edit-3 {
      th.edit-column {
        width: calc(32.17px * 3 + 7px);
      }
    }
    &.table-edit-4 {
      th.edit-column {
        width: calc(32.17px * 4 + 7px);
      }
    }
    &.table-edit-5 {
      th.edit-column {
        width: calc(32.17px * 5 + 7px);
      }
    }
    &.table-edit-6 {
      th.edit-column {
        width: calc(32.17px * 6 + 7px);
      }
    }
    &.table-edit-7 {
      th.edit-column {
        width: calc(32.17px * 7 + 7px);
      }
    }
  }
  td {
    white-space: pre;
    font-size: 0.75rem;
    color: $gray-600;
    border-top: none;
    padding: 11px 0.75rem;
    text-overflow: ellipsis;
    max-width: 10rem;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;

    .general-content-column {
      width: fit-content;
    }

    .more-icon {
      transform: rotate(90deg);
    }

    .drop-left {
      position: absolute;
      min-width: 2rem;
      font-size: unset;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-left: 3px;
      top: -2px !important;
      right: 100% !important;
      left: auto !important;
      height: 40px;
    }
    .action-button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: 0 4px;
      padding-top: 4px;
      display: flex;
      align-items: center;

      .close-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .action-item-button {
      cursor: pointer;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 10px;
      padding-bottom: 7px;
      margin: 0;
      top: 0;
      @include dm-color(color, $gray-800, $gray-100);

      &:hover {
        @include dm-color(
          background,
          $blue-light,
          $blue-light,
          1,
          '!important'
        );
      }

      &.single-action-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: -3px;
      }
    }
  }
  .table-overflow-scroll-inner[class*='table-edit-'] td .drop-left {
    right: 0% !important;
  }
  .close-icon {
    padding-left: 0;
    font-size: 20px;
  }

  .edit-column {
    overflow: inherit;
    position: absolute !important;
    right: 0rem;
    top: auto;
    border-left-color: $gray-400;
    border-left-width: 1px;
    border-left-style: solid;
    @include dm-color(background, $gray-100, $gray-100);
    width: 40px;
    height: 40px;

    &:not(th) {
      @include dm-color(background, $white, $white);
      border-right: none;
      span {
        color: $gray-800;
      }
      &:hover {
        @include dm-color(
          background,
          $blue-light,
          $blue-light,
          1,
          '!important'
        );
      }
    }
  }
  th.edit-column {
    margin-top: 0;
  }
  .edit-column-temp {
    min-width: 4rem;
    width: 4rem;
  }
  .edit-button {
    margin-right: 40px;

    &.table-edit-2 {
      margin-right: calc(32.17px * 2 + 5px);
    }
    &.table-edit-3 {
      margin-right: calc(32.17px * 3 + 5px);
    }
    &.table-edit-4 {
      margin-right: calc(32.17px * 4 + 5px);
    }
    &.table-edit-5 {
      margin-right: calc(32.17px * 5 + 5px);
    }
    &.table-edit-6 {
      margin-right: calc(32.17px * 6 + 5px);
    }
    &.table-edit-7 {
      margin-right: calc(32.17px * 7 + 5px);
    }

    .rr-item-open {
      @include dm-color(
        background-color,
        $gray-250,
        $gray-250,
        1,
        '!important'
      );
      &:hover {
        @include dm-color(
          background-color,
          $gray-250,
          $gray-250,
          1,
          '!important'
        );
      }
    }
  }

  .table-headline-sort,
  .table-headline-name {
    padding: 0;
  }
  .table-headline-container {
    white-space: nowrap;
    width: 50px;
    height: 15px;

    .chart-table-container & {
      width: auto;
      height: auto;
    }
  }
  .table-headline-name {
    padding-right: 2rem;
    width: calc(100% - 3rem);
    position: absolute;
    overflow: hidden;
    font-weight: 700;

    .chart-table-container & {
      width: auto;
      position: static;
      overflow: visible;
    }
  }
  .table-headline-sort {
    max-width: 2rem;
    text-align: right;
    white-space: pre;
    position: absolute;
    right: 10px;
    top: 6px;
    height: 70%;
    font-size: 9px;

    button {
      display: block;
      width: 100%;
      height: 50%;
      text-align: center;
      position: relative;
      color: $gray-icon;
      transition: color 200ms ease;

      &:hover {
        @include dm-color(color, $gray-800, $gray-100);
      }
      span {
        position: absolute;
        &:before {
          position: absolute;
          bottom: 1px;
        }
        &:last-child:before {
          top: 1px;
        }
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      @include dm-color(background, $gray-200, $gray-800);

      .edit-column {
        @include dm-color(background, $gray-200, $gray-800);
      }
    }
  }

  .table-overflow-scroll-inner {
    &.table-edit-2 {
      td.edit-column {
        width: calc(32.17px * 2 + 7px);
      }
    }
    &.table-edit-3 {
      td.edit-column {
        width: calc(32.17px * 3 + 7px);
      }
    }
    &.table-edit-4 {
      td.edit-column {
        width: calc(32.17px * 4 + 7px);
      }
    }
    &.table-edit-5 {
      td.edit-column {
        width: calc(32.17px * 5 + 7px);
      }
    }
    &.table-edit-6 {
      td.edit-column {
        width: calc(32.17px * 6 + 7px);
      }
    }
    &.table-edit-7 {
      td.edit-column {
        width: calc(32.17px * 7 + 7px);
      }
    }
  }
}

.type-checkbox {
  max-width: 2.5rem !important;
  width: 2.5rem !important;

  .btn-checkbox {
    padding: 0;
    display: inline-block;
    width: 1rem;
    &:before,
    &:after {
      left: 0;
    }
    &.checked:after {
      left: 4px;
    }
    &:focus {
      outline: none;
    }
  }
}

.table thead.thead-light th {
  vertical-align: middle;
  position: relative;

  /*  @include dm-color(background, $gray-200, $gray-200);*/
  /* @include dm-color(color, $gray-800, $gray-100);*/
  @include dm-color(border-color, $gray-500, $gray-400);

  border-width: 1px;
  border-top: none;
  border-bottom: none;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      @include dm-color(background, $gray-400, $gray-800);
      height: 100%;
      top: 0;
    }
  }
}
.table-view-component {
  position: relative;
}

.filtered-table-container {
  position: relative;
}
.empty-table {
  text-align: center;
  color: $gray-icon;
  padding: 4rem 1rem;

  a {
    position: relative;
    display: inline-block;
    padding-right: 2rem;
  }
  p {
    margin-bottom: 0;
  }
}

.table-top-bar {
  .btn {
    border-bottom: 0;
  }
  .fields-item {
    padding-top: 0.15rem;
    padding-bottom: 0.5rem;
    &:hover {
      background-color: $gray-light;
    }
  }
  .justify-content-start {
    margin-left: 0;
  }

  .filter-btn-fields {
    div {
      @include dm-color(color, $gray-800, $gray-100);
      font-size: 12px;
      margin-left: 0.5rem;
    }
    button {
      background: none;
      @include dm-color(color, $gray-800, $gray-100);
      font-size: 12px;
      margin-left: 0.5rem;
      @include dm-color(border-color, $gray-400, $gray-400);
      border-width: 1px;
      border-style: solid;
      border-top: none;
      border-right: none;
      border-bottom: none;
      border-radius: 0;
      height: 36px;
      .pagination-container & {
        border-bottom: 0;
      }
    }
  }
  .filter-btn-add {
    @include dm-color(background, $white, $gray-800);
    opacity: 0.7;
    @include dm-color(color, $black, $gray-100);
    border: none;
    transition: all 200ms ease;
    font-size: 12px;
    border-bottom: 0;

    .pagination-container & {
      border-bottom: 0;
    }

    span.oi {
      font-size: 10px !important;
      margin-left: -7px;
    }
  }
}
.top-table-container {
  position: relative;
  height: 40px;
}

.top-table-element {
  position: absolute;
  width: 100%;
  left: 0;
}

.status-field {
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 8pt;
  padding: 5px;
  background: none !important;
  display: inline-block;
  position: relative;
  padding-left: 1.5rem;
  text-transform: lowercase;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    width: 1.15rem;
    height: 1.15rem;
    border-radius: 50%;
    top: 3px;
  }
}
td .status-field {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;

  &:before {
    top: -2px;
  }
}

.table-filter-container {
  border-radius: 4px;
  border: 1px solid;
  border-color: $gray-400;
  padding: 1rem;
  margin-bottom: 1rem;

  label {
    text-transform: uppercase;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
}
.filter-sort-form {
  position: relative;

  > .form-group {
    position: absolute;
    right: 1rem;
    bottom: 0;
  }
}
.filter-sort-form-container {
  > div {
    display: inline-block;
    margin-right: 1.5rem;

    &:last-child {
      padding-right: 150px;
    }
  }
}

.thumbnail-container {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .thumbnail {
    max-width: 100%;
    max-height: 100%;
  }
}

.big-thumbnail {
  width: 100%;
  height: 100%;
}

.tags-container {
  rr-tags {
    display: flex;
    align-items: center;
  }
}
