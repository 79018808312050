.error-message {
  color: red;
  display: block;
  width: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  font-size: $input-btn-font-size;
}
.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;
}

.tags-filter-container > * {
  margin: 0 8px;
}

.add-tag-filter-btn {
  margin-bottom: 1rem;
  height: 40px;
}
