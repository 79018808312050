.custom-dropdown {
  position: relative;
  width: 200px;
  margin-bottom: 0;
}

.dropdown-button {
  pointer-events: none;
  position: absolute;
  right: 1.8rem;
  width: 1.8rem;
  display: block;

  &.activate {
    pointer-events: all;
    cursor: pointer;
  }

  &:after {
    display: inline-block;
    vertical-align: 2px;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    font-size: 1rem;
    margin-left: -1px;
  }
}

.dropdown-config-filter {
  min-width: 19em;
  max-width: 100%;
  max-height: 30vh;
  overflow: auto;
}

.dropdown-config-filter,
.dropdown-config-filter button {
  font-size: 0.9rem;
  text-align: left;
}
