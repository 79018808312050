.progressbar {
  margin-bottom: 3vh;
  overflow: hidden;
  padding-left: 0px;
  margin-top: 3vh;
  li {
    list-style-type: none;
    font-size: 0.8rem;
    // width: 8rem;
    float: left;
    position: relative;
    font-weight: 400;
    text-align: center;
    .step {
      width: 20px;
      height: 20px;
      margin-left: 0;
      line-height: 29px;
      display: block;
      font-size: 12px;
      background: $gray-500;
      border-radius: 50%;
      margin: auto;
      z-index: -1;
      margin-bottom: 1vh;
      .check {
        position: relative;
        top: -4px;
        color: $spacegrey-150;
      }
    }
    .line {
      content: '';
      height: 3px;
      background: $gray-500;
      position: absolute;
      left: 61%;
      width: 77%;
      margin-bottom: 2vh;
      top: 8px;
      z-index: 1;
      margin-right: auto;
    }
    .text {
      color: grey;
      position: relative;
    }
  }
  li:not(.active) {
    .step {
      opacity: 0.25;
    }
  }
  li.last:after {
    content: none;
  }
}

.progress-track {
  padding: 0 8%;
}

#progressbar li:after {
  margin-right: auto;
}

.three {
  font-size: 1.2rem;
}
.dashed {
  border-top: 4px dashed $gray-500;
  background: transparent !important;
}
.x10 {
  width: 10%;
}
.x9 {
  width: 11%;
}
.x8 {
  width: 12%;
}
.x7 {
  width: 14%;
}
.x6 {
  width: 16%;
}
.x5 {
  width: 20%;
}
.x4 {
  width: 25%;
}
.x3 {
  width: 33%;
}
.x2 {
  width: 50%;
}
