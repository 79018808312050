.batch-options {
  margin-left: 0.5rem;
  padding: 0;
  font-size: 12px;

  .d-flex {
    height: 100%;
    padding-right: 5px;
  }

  label {
    padding: 0 0.5rem;
    margin: 0;
  }
  select {
    font-size: 12px;
    padding: 0.2rem 0.5rem;
    margin-right: 1px;
    height: 1.7rem;
  }
  button {
    border-radius: 5px;
    font-size: 12px;
    padding: 0.25rem 0.5rem;

    &:hover {
      border-radius: 5px;
    }
  }
}

.batch-container {
  background-color: $purple;
  color: $white;
  font-size: 12px;
  overflow: hidden;
  transition: all 350ms ease;

  &.open {
    max-height: 50px;
  }

  .batch-items-selected-container {
    padding: 8px 12px;
  }

  .batch-actions-container {
    display: flex;
    flex-direction: row;
    padding: 0px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }

    .batch-action-container {
      padding: 8px 12px;

      .oi {
        margin-right: 4px;
      }
    }

    .custom-batch-option {
      display: none;
    }

    .batch-action-container:hover {
      cursor: pointer;
      background: #bdc1c9;
      color: white;
    }

    .batch-action-container:active {
      outline: 2px solid #ced4da;
      outline-offset: -0.125rem;
    }
  }
}

rr-table-custom-batch-options {
  display: flex;
  flex-direction: row;
}
