.stepper {
  justify-content: center;
  .stepper-body {
    max-height: 300px;
    overflow: auto;
  }
  .stepper-text {
    justify-content: center;
  }
  .step-item {
    width: 100%;
    border-bottom: $gray-250;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    height: 30px;
    display: inline-block;
    margin: 5px;
  }
  .active {
    border-bottom: black;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
  .navigation-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  }
}
