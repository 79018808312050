$font-family-base: 'Inter' !default;

$color-pictofit-red: #ef6650;
$selected-hover-color: $color-pictofit-red;

/* overwrite bootstrap variables */
$white: white;

$gray-050: #eeeeee;
$gray-100: #e3e3e4;

$gray-200: #eeeeee;
$gray-250: #c4c4c6;
$gray-300: #e3e3e4;
$gray-350: #e9e9ea;
$gray-400: #d9d9da;
$gray-450: #c1c1c3;
$gray-500: #909193;
$gray-600: #909193;
$gray-650: #707070;
$gray-700: #5b5d61;
$gray-750: #5b5d61;
$gray-800: #3b3d41;
$gray-900: #27292e;
$gray-icon: #6c757d;
$gray-blue: #93999e;

$spacegrey-250: #c3c3c5;
$spacegrey-150: #d8d8d9;

$black: #000;

$grays: (
  '250': $gray-250,
  '350': $gray-350,
  '450': $gray-450,
  '650': $gray-650,
);

$gray-lightblue: #f5f9fc;
$gray-darkblue-400: rgb(78, 91, 102); // 232A30

$blue: #536da9;
$indigo: #6610f2;
$purple: #605c7f;
$purple-250: #d2d1da;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc106;
$green: #53a966;
$teal: #20c997;
$cyan: #17a2b8;
$blue-light: #dbeaef;
$white: #ffffff;
$gray-light: #f0f2f4;
$gray-medium: #42464f;

$colors: (
  'purple-light': $purple-250,
  'blue-light': $blue-light,
  'gray-light': $gray-light,
  'gray-medium': $gray-medium,
);

$table-blue: #a8c8ce;
$table-border: #819498;

/* overwrite bootstrap variables */
$state-gray: $gray-600;
$state-darkgray: $gray-800;
$state-deepskyblue: deepskyblue;
$state-blue: $blue;
$state-lightblue: lightblue;
$state-navy: navy;
$state-purple: $purple;
$state-yellow: $yellow;
$state-orange: orange;
$state-darkorange: darkorange;
$state-red: $red;
$state-indianred: indianred;
$state-green: $green;
$state-darkgreen: #006400;
$desaturated: #0f550f;

$grid-gutter-width: 32px;

$margin: -($grid-gutter-width * 0.5);
$padding: ($grid-gutter-width * 0.5);

$btn-padding-y: 12px;
$btn-padding-x: 24px;
$btn-font-size: 14px;
$btn-line-height: 24px;
$btn-border-radius: 48px;

$primary: $gray-900;
$secondary: $purple;
$tertiary: $color-pictofit-red;

$theme-colors: (
  'white': $white,
  'grayblue': $gray-blue,
  'purple-250': $purple-250,
  'spacegrey-150': $spacegrey-150,
  'spacegrey-250': $spacegrey-250,
  'tertiary': $tertiary,
);

/* state color defaults */
$state-primary: #000000 !default;
$state-created: #62605e !default;
$state-in-progress: #ffc106 !default;
$state-finished: #3d6ce7 !default;
$state-approved: #53a966 !default;
$state-rejected: #dc3545 !default;
$state-canceled: #000000 !default;

$state-colors: (
  'state-gray': $state-gray,
  'state-darkgray': $state-darkgray,
  'state-deepskyblue': $state-deepskyblue,
  'state-blue': $state-blue,
  'state-lightblue': $state-lightblue,
  'state-purple': $state-purple,
  'state-yellow': $state-yellow,
  'state-orange': $state-orange,
  'state-darkorange': $state-darkorange,
  'state-red': $state-red,
  'state-indianred': $state-indianred,
  'state-green': $state-green,
  'state-darkgreen': $state-darkgreen,
  'state-navy': $state-navy,
  'state-primary': $state-primary,
  'state-created': $state-created,
  'state-in-progress': $state-in-progress,
  'state-finished': $state-finished,
  'state-approved': $state-approved,
  'state-rejected': $state-rejected,
  'state-canceled': $state-canceled,
);

@each $name, $color in $state-colors {
  .color-#{$name} {
    color: $color;
  }

  .bg-color-#{$name} {
    background-color: $color;
  }

  .border-color-#{$name} {
    border-color: $color;
  }
}

$body-bg: #f8f8f8;
$body-color: $black;

$link-color: #acacae;
$link-hover-color: $color-pictofit-red;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px,
);

$grid-gutter-width: 16px;

$border-color: $gray-500;

$border-radius: 4px;
$border-radius-lg: 4px;
$border-radius-sm: 4px;

$font-size-base: 1rem;
$h1-font-size: 24px;
$h2-font-size: 24px;
$h3-font-size: 16px;
$h4-font-size: 14px;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$custom-input-font-size: 12px;
$custom-input-font-line-hieght: 1.19;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1rem;
$input-group-addon-bg: $white;
$input-height: 40px;
$input-height-sm: 30px;
$input-height-lg: 50px;
$input-btn-padding-y: 0.3rem;
$input-btn-padding-x: 1.125rem;

$input-btn-padding-y-sm: $input-btn-padding-y;
$input-btn-padding-x-sm: $input-btn-padding-x;

$input-btn-padding-y-lg: $input-btn-padding-y;
$input-btn-padding-x-lg: $input-btn-padding-x;

$input-btn-font-size: $custom-input-font-size;
$input-btn-line-height: $custom-input-font-line-hieght;

$input-btn-font-size-sm: 10px;
$input-btn-line-height-sm: $custom-input-font-line-hieght;

$input-btn-font-size-lg: 14px;
$input-btn-line-height-lg: $custom-input-font-line-hieght;

$input-disabled-bg: #e9e9e9;

$btn-font-size: 14px;
$btn-font-size-sm: 12px;
$btn-font-size-lg: 16px;

$btn-padding-x: 24px;
$btn-padding-y: 12px;
$btn-padding-y-sm: 0.35rem;
$btn-padding-x-sm: 0.35rem;
$btn-padding-y-lg: 0.75rem;
$btn-padding-x-lg: 0.75rem;

$breadcrumb-bg: $purple;
$breadcrumb-border-radius: 0;

$list-group-disabled-bg: rgba($purple, 0.5);
$list-group-active-bg: rgba($purple, 0.75);

$table-color: $gray-900;
$table-head-color: $gray-900;
$table-head-bg: $gray-350;

$progress-border-radius: 10px;
$progress-bg: $blue-light;
$progress-bar-bg: $purple;

$dropdown-link-active-bg: $blue-light;
$dropdown-link-active-color: $gray-900;
$tooltip-max-width: none;
$tooltip-font-size: 0.75rem;
$tooltip-margin: 0;

$modal-md: 750px;

@import 'rr-styles';
