.checkbox-item {
  margin-top: 0.5rem;
}
.btn-checkbox,
.btn-radio {
  padding-left: 3rem;
  border: none;
  background: none;
  text-align: left;
  position: relative;
  -webkit-appearance: none !important;

  &:hover {
    background-color: $gray-light;
  }
  &:before {
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
    @include dm-color(border-color, $gray-500, $gray-500);
  }
  &:before,
  &:after {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    content: '';
  }
  &.checked:before {
    @include dm-color(border-color, $gray-750, $gray-750);
  }
  &.checked:after {
    display: inline-block;
    font-family: 'Icons';
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 8px;
    content: '\e033';
    width: 1em;
    text-align: center;
    box-sizing: content-box;
    left: 20px;
    color: $gray-800;
  }

  &.btn-small {
    padding-right: 0.25rem;
    padding-left: 2rem;
    &:before {
      left: 0.5rem;
    }
    &.checked:after {
      left: 9px;
    }
  }
}
.btn-radio {
  &:before {
    border-radius: 50%;
  }
  &.checked:after {
    display: inline-block;
    content: '';
    width: 0.5em;
    height: 0.5rem;
    padding: 0;
    line-height: 1;
    border-radius: 50%;
    @include dm-color(background, $black, $white);
    left: 1.25rem;
    font-size: 1rem;
  }
}

.form-check {
  position: relative;
  padding-left: 0;

  .form-check {
    padding-left: 1.5rem;
  }
}

.form-check-input {
  margin-left: 0;
  visibility: hidden;

  &[type='checkbox'] + label,
  &[type='radio'] + label {
    position: relative;
    padding-left: 1.5rem;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include dm-color(color, $gray-450, $gray-100);
      @include dm-color(border-color, $gray-450, $gray-100);
    }
    &:before {
      width: 1rem;
      height: 1rem;
      border-width: 1px;
      border-style: solid;
      border-radius: 2px;
    }
    &:after {
      text-align: center;
      content: '\e033';
      font-size: 12px;
      display: inline-block;
      font-family: 'Icons';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 1rem;
      opacity: 0;
      transition: opacity 200ms ease;
    }
  }
  &[type='radio'] + label:before {
    border-radius: 50%;
  }
  &[type='checkbox']:checked + label,
  &[type='radio']:checked + label {
    &::before,
    &:after {
      @include dm-color(color, $gray-650, $gray-100);
      @include dm-color(border-color, $gray-650, $gray-100);
    }
    &:after {
      opacity: 1;
    }
  }
  &[type='radio']:checked + label {
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      display: inline-block;
      @include dm-color(background, $gray-blue, $gray-700);
      border-radius: 50%;
      left: 3px;
    }
  }
  &[type='checkbox']:disabled + label,
  &[type='radio']:disabled + label {
    &::before,
    &:after {
      @include dm-color(color, $gray-350, $gray-100);
      @include dm-color(border-color, $gray-350, $gray-100);
    }
  }
}
